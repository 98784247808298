import { createSlice } from '@reduxjs/toolkit';

const initial = {
	borrowedGames: []
}

const slice = createSlice({
	name: 'user',
	initialState: initial,

	reducers: {
		reset: (state, action) => {
			state.borrowedGames = []
		},

		setBorrowedGames: (state, action) => {
			console.log("HOOK", action)
			state.borrowedGames = action.payload;
		},

	},
});

export const getBorrowedGames = state => state.borrowedGames;
export const {reset, setBorrowedGames} = slice.actions;

export default slice.reducer;
