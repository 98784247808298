// import logo from './logo.svg';
import './style.scss';
import {
	HashRouter as Router,
	Switch,
	Route,
	Redirect,
	useParams
} from "react-router-dom";
import {useEffect} from 'react'

function App() {

	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/:arg1/:arg2/:arg3/:arg4/:arg5"><MyRedirect/></Route>
					<Route path="/:arg1/:arg2/:arg3/:arg4"><MyRedirect/></Route>
					<Route path="/:arg1/:arg2/:arg3"><MyRedirect/></Route>
					<Route path="/:arg1/:arg2"><MyRedirect/></Route>
					<Route path="/:arg1"><MyRedirect/></Route>
					<Route path="/"></Route>
				</Switch>
			</Router>
		</div>
	);
}

const MyRedirect = props => {
	let params = useParams()
	useEffect(() => {
		let url = 'https://ginso89.onrender.com/#/' + params.arg1
		let i = 2
		while(params['arg'+i]) url += '/' + params['arg'+i++]
		window.location.replace(url);
  }, []);
	return <div/>
}

export default App;
